<template>
    <div>
        <top-data></top-data>
        <div class="mt-2 flex justify-end">
            <el-button type="success" @click="scollHandle(1)">上一周</el-button>
            <el-button type="success" @click="scollHandle(2)">下一周</el-button>
        </div>
        <table id="main" class="wh mt-2 pct100" cellpadding="10">
          <tr id="head">
            <th>场地</th>
            <th>场次</th>
          </tr>
        </table>
    </div>
</template>
<script>
import TopData from '../../common/components/normal/TopData.vue';
import testJosn from '../../apidata/test.json';
import moment from "moment";

export default {
  name: 'fieldOrder',
  components: {
    TopData
  },
  data(){
    return{
      timeList: [],
      testJosn,
      orderList:[],
      week:0,
      today:moment().format("YYYY-MM-DD"),

    }
  },
  methods: {
    async getOrderList() {
      // var day1 = '2024-06-01'
      const { res } = await this.$http.get(`/FieldVenue/WeekFieldVenueSchedule/${this.today}`);
      if (res.isSuccess) {
        this.orderList = res.data;
        this.getTimeList();
      }
    },
    // 时间轴
    getTimeList() { 
      this.week = new Date().getDay();
      console.log('初始化时间数组-----', this.week);
      let arr = [];
      if (this.week == 1) {
        arr = [0, 1, 2, 3, 4, 5, 6];
      } else if (this.week == 2) {
        arr = [-1, 0, 1, 2, 3, 4, 5];
      } else if (this.week == 3) {
        arr = [-2, -1, 0, 1, 2, 3, 4];
      } else if (this.week == 4) {
        arr = [-3, -2, -1, 0, 1, 2, 3];
      } else if (this.week == 5) {
        arr = [-4, -3, -2, -1, 0, 1, 2];
      } else if (this.week == 6) {
        arr = [-5, -4, -3, -2, -1, 0, 1];
      } else if (this.week == 0) {
        arr = [-6, -5, -4, -3, -2, -1, 0];
      }
      this.activeIndex = arr.findIndex((item) => item === 0);
      this.timeList = [];
      arr.forEach((item) => {
        this.timeList.push(this.getTime(item));
      });
      // this.selectDay(this.activeIndex);
      console.log('初始化时间数组-----', this.timeList);


      let head = document.getElementById("head");
      console.log(head);
      for(var i =0;i<7;i++){
        let newTh = document.createElement("th");
        newTh.innerHTML = this.timeList[i].month + '月' + this.timeList[i].day + '日'+ '(' + this.timeList[i].weekStr + ')';
        head.appendChild(newTh);
      }
      console.log("2");
      let mainTr = document.getElementById("main");
//循环场次
      this.orderList.forEach((item, index) =>{  
        let newTr = document.createElement("tr");
        let newTd = document.createElement("td");    

        newTd.innerHTML = item.venueName;
        console.log("23");
        if(this.orderList[index].weekScheduleList.length){
          newTd.setAttribute('rowspan',this.orderList[index].weekScheduleList.length); 
        }
        console.log(newTd);
        newTr.appendChild(newTd);
        console.log(newTr);

        //循环场次
        console.log(this.orderList[index].weekScheduleList);
        if(this.orderList[index].weekScheduleList.length){
          this.orderList[index].weekScheduleList.forEach((e,i)=>{
          console.log("1");
          let newTr3 = document.createElement("tr");
          let newTd2 = document.createElement("td");
          newTd2.innerHTML = e.time;         
          

          if(i>0){       
            newTr3.appendChild(newTd2);
            mainTr.appendChild(newTr3);
          }else{
            newTr.appendChild(newTd2);
          }

          this.orderList[index].weekScheduleList[i].weekScheduleInfoList.forEach((a)=>{
          console.log(e);
          let newTd3 = document.createElement("td");
          newTd3.innerHTML = a.scheduleTitle;
          if(i==0){
            newTr.appendChild(newTd3);
            mainTr.appendChild(newTr);  
          }else{
            newTr3.appendChild(newTd3);
          }
        }); 

        // mainTr.appendChild(newTr);  

        }); 
        } else {
          mainTr.appendChild(newTr);
          console.log("1");
        }
        // console.log("1");
        // mainTr.appendChild(newTr);   
        // console.log(this.testJosn.children);
      });


    },
    getTime(timeIndex) {

      let time = +new Date(this.today) + timeIndex * 24 * 60 * 60 * 1000;
      let date = new Date(time);
      let week = date.getDay();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let weekStr = '';
      if (week == 1) {
        weekStr = '周一';
      } else if (week == 2) {
        weekStr = '周二';
      } else if (week == 3) {
        weekStr = '周三';
      } else if (week == 4) {
        weekStr = '周四';
      } else if (week == 5) {
        weekStr = '周五';
      } else if (week == 6) {
        weekStr = '周六';
      } else if (week == 0) {
        weekStr = '周日';
      }
      let timeData = {
        year,
        month,
        day,
        week,
        weekStr,
        timeIndex,
      };
      return timeData;
    },
    addTime() {
      let arr = [];
      let num = this.timeList[this.timeList.length - 1].timeIndex;
      for (let i = 1; i < 8; i++) {
        arr.push(num + i);
      }
      arr.forEach((item) => {
        this.timeList.push(this.getTime(item));
      });
    },
    scollHandle(type) {
      if (type == 1) {
        //上一周
        this.today = moment(this.today).add(-7, 'day').format("YYYY-MM-DD  ");
      } else {
        //下一周
        this.today = moment(this.today).add(7, 'day').format("YYYY-MM-DD  ");
      }
      
        document.getElementById('main').innerHTML = '';
        let newTr = document.createElement("tr");
        newTr.id = 'head';

        let newTh = document.createElement("th");
        newTh.innerHTML = '场地';
        let newTh1 = document.createElement("th");
        newTh1.innerHTML = '场次';
        newTr.appendChild(newTh);
        newTr.appendChild(newTh1);
        document.getElementById('main').appendChild(newTr);


        console.log(this.timeList);
        console.log(this.today);
        this.getOrderList();
    },
  },
  mounted() {  
    this.getOrderList();
  }
}
</script>
<style lang="scss">
table, th , tr, td {
  border: 1px solid rgba(0, 180, 255, 1);
  background-image: linear-gradient(to bottom, rgba(0, 72, 122, 0.16), rgba(0, 72, 122, 0.184));
  border-collapse: collapse; /* 移除表格内边框间的间隙 */;
  vertical-align: middle;
  text-align: center;
}
</style>
